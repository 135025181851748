//
// Icons
//

// Styling for the rounded social media links

.social-icons {
	.social-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 3.5rem;
		width: 3.5rem;
		background-color: $gray-700;
		color: $white;
		border-radius: 100%;
		font-size: 1.5rem;
		margin-right: 1rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		text-decoration: none;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			background-color: $primary;
		}
	}
}

// Styling for the development technology icons
.dev-icons {
	font-size: 3rem;
}
