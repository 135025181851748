//
// Global
//

body {
	padding-top: $navbar-base-height;
	color: $gray-600;
}

@include media-breakpoint-up(lg) {
	body {
		padding-top: 0;
		padding-left: $sidebar-base-width;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	text-transform: uppercase;
}

h1 {
	font-size: 6rem;
	line-height: 5.5rem;
}

h2 {
	font-size: 3.5rem;
}

h3 {
	font-size: 2rem;
}

p.lead {
	font-size: 1.15rem;
	font-weight: 400;
}

.subheading {
	font-family: $headings-font-family;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 1.5rem;
}

.img-resume {
	max-width: 10rem;
	max-height: 10rem;
	border: 0.3rem solid $primary;
}

li > i {
	color: $primary;
}

.bg-primary {
	background-color: $primary !important;
}

.text-primary {
	color: $primary !important;
}

a {
	color: $primary;
	text-decoration: none;
	&:hover,
	&:focus,
	&:active {
		color: darken($primary, 15%);
	}
}

@media screen and (prefers-color-scheme: dark) {
	body {
		background-color: $gray-900;
		color: white;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $gray-600;
	}

	.resume-date > .text-primary {
		color: lighten(saturate($primary, 35%), 15%) !important;
	}

	.subheading {
		color: $gray-400;
	}
}
